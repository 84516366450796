import $ from 'jquery'; 

class CodeBox {
  constructor() {
    this.$container = $('#codebox'); 

    // CHECK IF ELEMENT IS PRESENT THEN INITIALIZE
    if(this.$container.length) {
      // REGISTER ELEMENTS
      this.$nav = $(this.$container.find("#codebox-nav"))
      this.$buttons = this.$nav.find('button'); 
      this.$codeExamples = this.$container.find('.codebox-code'); 

      // ACTIVATE CODEBOX
      this.activate(); 
    }
  }

  activate() {
    this.$buttons.on('click', (e) => {
      // DISABLE ALL OTHER BUTTONS AND EXAMPLES
      this.$buttons.removeClass('is-active'); 
      this.$codeExamples.removeClass('is-active'); 

      // GET BUTTON AND MATCHING CODE EXAMPLE
      const $button = $(e.target) 
      const name = $button.attr('name'); 
      const $code = this.$container.find(`#${name}`); 

      // MAKE SELECTED EXAMPLE ACTIVE
      $button.addClass('is-active');
      $code.addClass('is-active'); 
    })
  }
}

export default CodeBox;