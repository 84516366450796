import $ from 'jquery'; 

class TopNav {
  constructor() {
    this.$nav = $('#top-nav'); 

    // CHECK IF ELEMENT IS PRESENT THEN INITIALIZE
    if(this.$nav.length) {
      // REGISTER ELEMENTS
      this.$menu = $(this.$nav.find(".top-nav-menu"))
      this.$open = this.$nav.find('.top-nav-open'); 
      this.$close = this.$nav.find('.top-nav-close'); 
      this.$dropdownButtons = this.$nav.find('.dropdown-button'); 
      this.$dropdowns = this.$nav.find('.dropdown'); 

      // ACTIVATE CODEBOX
      this.activate(); 
    }
  }

  activate() {
    this.$open.on('click', (e) => {
      this.$open.toggleClass('is-active'); 
      this.$close.toggleClass('is-active'); 
      this.$menu.toggleClass('is-active'); 
    });

    this.$close.on('click', (e) => {
      this.$open.toggleClass('is-active'); 
      this.$close.toggleClass('is-active'); 
      this.$menu.toggleClass('is-active'); 
    });

    this.$dropdownButtons.on('click', (e) => {
      const $dropdown = $(e.target).siblings('.dropdown'); 
      $dropdown.toggleClass('is-active');
    })
  }
}

export default TopNav;