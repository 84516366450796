import $ from 'jquery'; 

class ShowCase {
  constructor() {
    this.$container = $('#showcase'); 

    // CHECK IF ELEMENT IS PRESENT THEN INITIALIZE
    if(this.$container.length) {
      // REGISTER ELEMENTS
      this.$nav = $(this.$container.find("#showcase-nav"))
      this.$buttons = this.$nav.find('button'); 
      this.$slides = this.$container.find('.showcase-slide'); 

      // ACTIVATE CODEBOX
      this.activate(); 
    }
  }

  activate() {
    this.$buttons.on('click', (e) => {
      // DISABLE ALL OTHER BUTTONS AND EXAMPLES
      this.$buttons.removeClass('is-active'); 
      this.$slides.removeClass('is-active'); 

      // GET BUTTON AND MATCHING CODE EXAMPLE
      const $button = $(e.target) 
      const name = $button.data('name'); 
      const $code = this.$container.find(`#showcase-${name}`); 

      // MAKE SELECTED EXAMPLE ACTIVE
      $button.addClass('is-active');
      $code.addClass('is-active'); 
    })
  }
}

export default ShowCase;