import $ from 'jquery'; 
import slick from 'slick-carousel'; 
import hljs from './third-party/highlight';
import TextCarousel from './modules/text-carousel'; 
import CodeBox from './modules/codebox';
import ShowCase from './modules/showcase';
import TopNav from './modules/top-nav';
//import GlobeElement from './modules/globe';

$(document).ready(function(){
  // HERO TEXT CAROUSEL
  new TextCarousel(); 

  // CODEBOX AND SYNTAX HIGHLIGHTING 
  hljs.highlightAll();
  new CodeBox(); 

  // USE CASES
  new ShowCase();

  // TOP NAV MOBILE INTERACTION 
  new TopNav(); 

  // 3D GLOBE
  //new GlobeElement(); 

  /* 
  * CUSTOMER CAROUSEL 
  *
  * docs: https://github.com/kenwheeler/slick/
  */
  $('.customer-carousel').slick({
    arrows: false,
    autoplay: true, 
    autoplaySpeed: 6000, 
    dots: true, 
    pauseOnFocus: true
  }); 
});