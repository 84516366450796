import $ from 'jquery'; 

class TextCarousel {
  constructor(id) {
    const elementId = '.text-carousel';

    // register elements & vars
    this.$textCarousel = $(elementId);

    if(this.$textCarousel.length) {
      this.$slides = this.$textCarousel.find('span');
      this.currentSlide = 0;
      this.$slide = $(this.$slides[this.currentSlide]);

      // activate navigation
      if(this.$slides.length) {
        this.activateSlides();
      }
    } 
  }

  switchSlide() {
    this.$slides.removeClass('is-active is-exiting');

    // ADD EXISTING CLASS TO CURRENT SLIDE
    this.$slide.addClass('is-exiting');

    // UPDATE CURRENT SLIDE
    if(this.currentSlide === (this.$slides.length - 1)) {
      this.currentSlide = 0;
    }
    else {
      this.currentSlide = this.currentSlide + 1 ;
    }

    // FIND NEXT SLIDE AND ADD IS ACTIVE
    this.$slide = $(this.$slides[this.currentSlide]);
    this.$slide.addClass('is-active');
  }

  activateSlides() {
    setInterval(() => {
      this.switchSlide(); 
    }, 4000);
  }
}

export default TextCarousel;